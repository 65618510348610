import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

// リンクタグを表示させるコンポーネント
const Li = ({ currentPageNumber, current, path }) => {
  console.log(`[Li Function] currentPageNumber: ${currentPageNumber}, current: ${current}, path: ${path}`);

  if (current) {
    return (
      <li className="c-pagination c-pagination--current">
        <span>{currentPageNumber}</span>
      </li>
    );
  }

  return (
    <li className="c-pagination">
      <Link to={path}>{currentPageNumber}</Link>
    </li>
  );
};

// スキップ用の要素を表示させるコンポーネント
const Skip = () => <li className="skip">...</li>;

const PreviousLink = ({ current, type }) => {
  // 一つ前に戻るページネーションのリンクまたは非アクティブ状態を決定する
  const getPreviousLink = () => {
    if (current === 1) {
      // 1ページ目だったら非アクティブ状態
      return { isActive: false, to: '' }; // オブジェクトを返す
    } else if (current === 2) {
      // 2ページ目だったらトップページに戻るリンクを表示
      return { isActive: true, to: '/' };
    } else {
      // 2ページ目以降だった場合は、現在のページより一つ前のページのリンクを表示
      // Caution: 相対パスを指定すると、http://localhost/現在のページ/page/current - 1とパスが入ってしまうので以下のように絶対パスを指定すること
      // 誤: to={`${type}/${current - 1}`}
      // 正: to={`/${type}/${current - 1}`}
      return { isActive: true, to: `/${type}/${current - 1}` };
    }
  };

  // 分割代入
  const { isActive, to } = getPreviousLink();

  return (
    <li
      className={`c-pagination ${!isActive ? 'not-work' : ''}`}
      key="pager-newer"
    >
      {isActive ? <Link to={to}>＜</Link> : <span>＜</span>}
    </li>
  );
};

// ページネーションを表示させるコンポーネント
const Pagination = ({ totalPageNumber, current, type }) => {
  if (totalPageNumber <= 0) return '';

  // 総ページ数までの連番を配列に変換する
  const pageNumberArr = [...Array(totalPageNumber).keys()].map(i => i + 1);
  // 総ページング数
  const totalPagination = pageNumberArr.length;
  // ページネーションを表示させる最大数
  const MAX_PAGINATION = 6;

  // 一つ先に進むページネーション
  let older;
  if (current === totalPageNumber) {
    older = (
      // 現在のページが総ページング数と同じだった場合は非アクティブにする
      // 総ページング数とは、記事の総数を1ページに表示させる件数で割った値
      <li
        className="c-pagination not-work"
        key="pager-older"
      >
        <span>＞</span>
      </li>
    );
  } else if (current === '') {
    older = (
      <li key="c-pagination pager-older">
        <Link to={`/${type}`}>＞</Link>
      </li>
    );
  } else {
    older = (
      // 最終ページ以外だったら一つ次のページのリンクを表示
      <li key="c-pagination pager-older">
        {/* 絶対パスを指定すること */}
        <Link to={`/${type}/${current + 1}`}>＞</Link>
      </li>
    );
  }

  // MAX_PAGINATION

  if (totalPageNumber > 0) {
    return (
      <PagerWrapper>
        <PreviousLink
          current={current}
          type={type}
        />

        {pageNumberArr.map(i => {
          const path = i === 1 ? `/` : `/${type}/${i}`;

          if (totalPageNumber > MAX_PAGINATION) {
            if (current <= 3 || current === '') {
              // 現在のページが3ページ目より小さい場合
              if (totalPagination === i) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager-first`}
                  ></Li>
                );
              } else if (totalPagination - 1 === i) {
                // 最終ページの一つ前をSkip要素にする

                return <Skip key={i}></Skip>;
              } else if (i < MAX_PAGINATION) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              }
              // 現在のページが総ページ数の-3より大きい場合
            } else if (current >= totalPagination - 3) {
              if (i === 1) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              }

              if (i === 2) {
                return <Skip key={i}></Skip>;
              } else if (totalPagination - 5 < i) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              }
              // それ以外の場合
              // 総ページの-3より小さく、現在のページが3ページ目より大きい場合
            } else {
              if (i === 1) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              } else if (i === 2) {
                return <Skip key={i}></Skip>;
              } else if (current + 3 > i && current - 2 < i) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              } else if (totalPagination === i) {
                return (
                  <Li
                    currentPageNumber={i}
                    current={current === i}
                    path={path}
                    type={type}
                    key={`pager${i}`}
                  ></Li>
                );
              } else if (totalPagination - 1 === i) {
                // 現在のページが総ページの一つ前のページだったら、その要素をスキップ要素に置き換える
                return <Skip key={i}></Skip>;
              }
            }
          } else {
            // 総ページ数が6より小さい場合はskip要素は表示されない
            return (
              <Li
                currentPageNumber={i}
                current={current === i}
                path={path}
                type={type}
                key={`pager${i}`}
              ></Li>
            );
          }
        })}
        {older}
      </PagerWrapper>
    );
  } else {
    return '';
  }
};

export default Pagination;

const PagerWrapper = styled.div`
  text-align: center;

  // モバイルファースト: 基本のスタイリング
  .c-pagination span {
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    transition: all 0.3s;
    font-weight: bold;
    font-size: 1.1rem;
    list-style: none;
  }

  .c-pagination {
    display: none; // モバイルでは非表示にする

    &--current {
      display: inline-block; // 現在のページのみ表示
      background-color: #57a1d8;
      color: #fff;
    }
  }

  li {
    display: inline-block;
    margin-left: 8px;

    &:nth-of-type(1) {
      margin-left: 0px;
    }

    @media screen and (min-width: 768px) {
      margin-left: 24px;
    }

    &:nth-of-type(1),
    &:last-child {
      display: inline-block; // 「前へ」「次へ」を表示
    }

    &.skip {
      display: none; // モバイルでは非表示
    }

    & a {
      display: block;
      width: 48px;
      height: 48px;
      line-height: 48px;
      border: solid 1px #57a1d8;
      color: #57a1d8;
      text-align: center;
      transition: all 0.3s;
      font-weight: bold;
      font-size: 1.1rem;
      list-style: none;

      &:hover {
        color: #fff;
        background-color: #57a1d8;
      }
    }

    & span {
      display: block;
      width: 48px;
      height: 48px;
      line-height: 48px;
      border: solid 1px #57a1d8;
      color: #fff;
      text-align: center;
      transition: all 0.3s;
      font-weight: bold;
      font-size: 1.1rem;
      list-style: none;
    }
  }

  // 画面幅が768px以上のときのスタイリング
  @media screen and (min-width: 768px) {
    .c-pagination {
      display: inline-block; // すべてのページネーションを表示
    }

    li {
      margin-left: 24px;

      &.skip {
        display: inline-block; // 「...」を表示
      }
    }
  }

  .not-work span {
    color: #57a1d8; // 「前へ」のスタイル
  }
`;
