import React, { useState } from 'react';
// import { useStaticQuery, graphql } from 'gatsby'
// import { Link } from 'gatsby'
import SearchResult from './search-result';
import styled from 'styled-components';

const Search = () => {
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState('');
  const onFocus = () => {
    setIsFocus(true);
  };
  const onBlur = () => {
    setIsFocus(false);
  };
  const onChange = e => {
    setValue(e.target.value);
  };

  return (
    <>
      <SearchArea>
        <SearchAreaInner>
          <label htmlFor="blog-search-box">記事を検索する</label>
          <InputContainer>
            <input
              type="text"
              name="blog-search"
              id="blog-search-box"
              placeholder="検索ワード"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
            />
          </InputContainer>
          <SearchResult
            focus={isFocus}
            value={value}
          />
        </SearchAreaInner>
      </SearchArea>
    </>
  );
};

export default Search;

const SearchArea = styled.div`
  label {
    display: block;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 8px;
    &::after {
      content: '';
      display: block;
      width: 4px;
      box-shadow: rgb(204, 204, 204) 10px 0px 0px, rgb(204, 204, 204) -10px 0px 0px;
      height: 4px;
      border-radius: 50%;
      background: rgb(204, 204, 204);
      margin: 10px auto 0px;
    }
  }
`;

const InputContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const SearchAreaInner = styled.div`
  padding: 16px;

  input {
    border: solid 1px #e3e0e0;
    padding: 8px 16px;
    width: 100%;
  }
`;
