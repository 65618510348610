/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author;

  return (
    <div>
      <BioImageWrapper className="img-fluid">
        <StaticImage
          className="bio-avatar"
          layout="fixed"
          formats={['auto', 'webp', 'avif']}
          src="../images/profile-pic.png"
          width={100}
          height={100}
          quality={95}
          alt="Profile picture"
        />
      </BioImageWrapper>
      <BioTitle>プロフィール</BioTitle>
      <BioAuthor>Author: {author.name}</BioAuthor>
      <p>
        とある事業会社のエンジニア。 デスクトップアプリやWebシステムの開発をしています。 ITエンジニア3年目。30代開発未経験から転職しました。 C#、JavaScript、PHP。4児の父（3人目が双子）。
        草野球チーム運営。2021/09/20 ~
        <a
          href="https://twitter.com/search?q=%23RecursionCS&src=typed_query"
          rel="noreferrer"
          target="_blank"
        >
          #RecursionCS
        </a>
      </p>

      <IconWrapper>
        <a href="https://github.com/shimanamisan">
          <FontAwesomeIcon
            icon={faGithub}
            className="bio-github"
          />
        </a>
        <a href="https://twitter.com/hn_pgtech">
          <FontAwesomeIcon
            icon={faTwitter}
            className="bio-twitter"
          />
        </a>
      </IconWrapper>
    </div>
  );
};

export default Bio;

const BioAuthor = styled.p`
  text-align: center;
`;

const BioImageWrapper = styled.div`
  & > div {
    border-radius: 50%;
    margin: 0 auto;
    padding: 0.5rem;
  }

  margin-bottom: 1rem;
`;

const BioTitle = styled.h1`
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 0.7rem;
`;

const IconWrapper = styled.div`
  text-align: center;

  a {
    color: #212529;
    font-size: 2rem;
    margin-left: 1.5rem;
    transition: all 0.3s;
    &:hover {
      opacity: 0.7;
    }
    &:first-child {
      margin-left: 0px;
    }
  }

  .bio-github {
    color: #212529;
  }

  .bio-twitter {
    color: rgb(29, 155, 240);
  }
`;
